import React from "react";
import { Outlet } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { Panel } from "primereact/panel";

import Logo from "./components/logo/Logo";
import Login from "./components/login/Login";

import "./App.css";

const menuItems = [
  {
    label: "Home",
    icon: "pi pi-fw pi-home",
    url: "/",
  },
  // {
  //   label: "About",
  //   icon: "pi pi-fw pi-info",
  //   url: "/about",
  // },
  // Add more menu items as needed
];

const App = () => (
  <div className="layout">
    <Menubar model={menuItems} start={Logo} end={Login} />
    <div className="layout-content">
      <Panel>
        <Outlet />
      </Panel>
    </div>
  </div>
);

export default App;
