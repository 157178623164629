// routes.tsx
import React from "react";
import { createBrowserRouter } from "react-router-dom";

import { ProtectedRoute } from "./components/protectedRoute/ProtectedRoute";
import App from "./App";
import Home from "./pages/Home/Home";
import About from "./pages/About";
import Error from "./pages/Error";

export type Router = ReturnType<typeof createBrowserRouter>;

export const router: Router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <ProtectedRoute><Home /></ProtectedRoute>,
      },
      {
        path: "/about",
        element: <ProtectedRoute><About /></ProtectedRoute>,
      },
    ],
  },
]);
