import { useCallback } from "react";
import { useField, useFormikContext } from "formik";
import { FileUpload, FileUploadHandlerEvent } from "primereact/fileupload";

function PrimeFileField({ fieldName }: { fieldName: string }) {
  const [{ value, ...field }, meta] = useField(fieldName);
  const { setFieldValue } = useFormikContext();
  const onHandleUpload = useCallback(
    (event: FileUploadHandlerEvent) => {
      const pendingFiles: File[] = event.files;
      setFieldValue(fieldName, pendingFiles);
    },
    [fieldName, setFieldValue]
  );

  return (
    <>
      <div>
        <FileUpload
          customUpload
          multiple
          auto
          accept="application/pdf"
          uploadHandler={onHandleUpload}
          emptyTemplate={<>Drag and drop files to here to upload.</>}
          pt={{ badge: { root: { style: { display: "none" } } } }}
          {...{ ...field, value }}
        />
      </div>
      {meta.touched && !!meta.error && (
        <div className="mt-3 text-danger">{meta.error}</div>
      )}
    </>
  );
}

export default PrimeFileField;
