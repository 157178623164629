import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import filesQuery from "../ducks/filesQuery";
import auth, { initialize as authInitialize } from "../ducks/auth";

const store = configureStore({
  reducer: {
    filesQuery: filesQuery.reducer,
    auth: auth.reducer,
  },
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

store.dispatch(authInitialize());

export default store;
