import { useField } from 'formik';
import { classNames } from 'primereact/utils';
import { InputTextarea } from 'primereact/inputtextarea';

function PrimeTextareaField({ fieldName, placeholder }:
  {
    fieldName: string;
    placeholder?: string;
  }
) {
  const [field, meta] = useField(fieldName);

  return (
    <>
      <div>
        <InputTextarea placeholder={placeholder} className={classNames('c-input', { '.p-invalid': meta.touched && !!meta.error })} {...field} />
      </div>
      {meta.touched && !!meta.error && <div className="mt-3 text-danger">{meta.error}</div>}
    </>
  );
}

export default PrimeTextareaField;
