import React, { useCallback } from "react";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, AppState } from "../../ducks";
import { login, logout } from "../../ducks/auth";

import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

function Login() {
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated = useSelector<AppState, boolean>(
    (state) => state.auth.isAuthenticated
  );
  const displayName = useSelector<AppState, string>((state) => state.auth.displayName);
  const handleLogin = useCallback(() => dispatch(login()), [dispatch]);
  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);

  return isAuthenticated ? (
    <div className="flex flex-row flex-wrap align-items-end">
      <div className="mx-3">{displayName}</div>
      <Button className="mx-3" onClick={handleLogout}>
        <span className="pi pi-user mr-2"></span>
        Log out
      </Button>
    </div>
  ) : (
    <Button className="mx-3" onClick={handleLogin}>
      <span className="pi pi-user mr-2"></span>
      Log in
    </Button>
  );
}

export default Login;
