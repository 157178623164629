import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { PublicClientApplication } from '@azure/msal-browser';

import { msalConfig } from './config/authConfig';

import store from "./ducks";
import { router } from "./routes";

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import "./index.css";


const msalInstance = new PublicClientApplication(msalConfig);
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    const account = accounts[0];
    msalInstance.setActiveAccount(account);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render( 
  <Provider store={store}>    
    <RouterProvider router={router} />
  </Provider>
);
