import React, { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppDispatch, AppState } from "../../ducks";
import { useDispatch } from "react-redux";
import { handleRedirectCallback } from "../../ducks/auth";

interface ComponentProps {
  children: ReactElement | ReactElement[];
}

export const ProtectedRoute: React.FC<ComponentProps> = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated = useSelector<AppState, boolean>(
    (state) => state.auth.isAuthenticated
  );

  useEffect(() => {
    if (window.location.hash) {
      console.log('Handling login redirect in protected route.');
      dispatch(handleRedirectCallback());
    }
  }, [dispatch]);

  if (isAuthenticated) {
    return <>{children}</>;
  }
  return <>You need to log in first.</>;
};
